<template>
    <div class="geofences">
        <div class="map-wrapper">
            <div id="map"></div>

            <v-alert type="warning" class="alert elevation-3" :value="needSave" :dismissible="true">
                {{ $t('geofence.need_save_geofences') }}
            </v-alert>



  <!-- <v-card>
    <v-tabs
      tile
      show-arrows
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab
        v-for="i in 3"
        :key="i"
        :href="'#tab-' + i"
      >
        Item {{ i }}
      </v-tab>
    </v-tabs>
  </v-card> -->


        </div>

        <v-fab-transition>
            <v-btn v-show="true" color="blue" dark absolute left bottom fab style="bottom: 15px" :disabled="!needSave" @click="onClickSave()">
                <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
        </v-fab-transition>

        <v-banner v-if="geofenceSelected != null" single-line :sticky="sticky" class="banner" :elevation="5">
            <v-form>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="geofence.name" label="Название" placeholder="Например, Вторая речка" @change="onNameSelect()"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <div class="colors_wrapper">
                                <div class="header">Цвет</div>
                                <div v-for="(color, index) in colors" :key="index" :style="{ 'background-color': color, width: colorWidth + 'px' }" :class="index == geofence.color ? 'selected' : ''" @click="onColorSelect(index)" class="color"></div>
                            </div>
                        </v-col>

                            <v-col cols="12">
                                <p class="my-0">Цена доставки, руб.</p>
                            </v-col>

                            <v-col cols="3">
                                <v-text-field v-model="geofence.price[0]" label="Цена 1" @change="onPriceSelect()"></v-text-field>
                            </v-col>

                            <v-col cols="3">
                                <v-text-field v-model="geofence.price[1]" label="Цена 2" @change="onPriceSelect()"></v-text-field>
                            </v-col>

                            <v-col cols="3">
                                <v-text-field v-model="geofence.price[2]" label="Цена 3" @change="onPriceSelect()"></v-text-field>
                            </v-col>

                            <v-col cols="3">
                                <v-text-field v-model="geofence.price[3]" label="Цена 4" @change="onPriceSelect()"></v-text-field>
                            </v-col>
                            
                            <v-col cols="12">
                                <p class="my-0 text-wrap">
                                    <v-icon color=warning class="mr-1">mdi-information-outline</v-icon>
                                    Не забудьте в <router-link :to="{ path: '/settings' }">настройках</router-link> назначить партнерам цены
                                </p>
                            </v-col>

                        <v-col cols="12">
                            <v-text-field v-model="geofence.descr" label="Описание" @change="onDescrSelect()"></v-text-field>
                        </v-col>

                        <!-- <v-col cols="12">
                            <v-switch v-model="geofence.notify" class="ma-2" label="Уведомлять при входе/выходе" @click="onNotifySelect()"></v-switch>
                        </v-col> -->

                    </v-row>
                </v-container>
            </v-form>
        </v-banner>
    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')
    moment.tz.setDefault(config.timezoneString)

    import * as L from 'leaflet'
    import 'leaflet/dist/leaflet.css'

    import '@geoman-io/leaflet-geoman-free'
    import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
    

    import { v4 as uuidv4 } from 'uuid'

    const cfg = require('../../config')

    // метод для копирования и слияния - полезный!
    // var obj = { a: 1 };
    // var copy = Object.assign({}, obj);

    /* leaflet icon */
    delete L.Icon.Default.prototype._getIconUrl
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    })

    export default {
        data() {
            return {
                sticky: false,
                needSave: false,
                map: '',
                geofences: {},
                styleInit: {
                    fillOpacity: 0.3,
                    opacity: 0.6,
                    weight: 1.5,
                    color: 'blue',
                },
                styleSelect: {
                    fillOpacity: 0.4,
                    opacity: 0.8,
                    weight: 3,
                    color: 'blue',
                },
                geofenceDraw: false,
                geofenceOver: false,
                geofenceDrag: false,
                markerDrag: false,
                geofenceSelected: null,
                colors: [],
                colorWidth: 0,
                geofence: {
                    id: 0,
                    color: 0,
                    name: '',
                    notify: false,
                    price: [],
                    descr: '',
                },
                geofenceSaved: true,
                geofenceSaveTimer: 0,
                timer: null,
                sat: cfg.geofence.color.saturation,
                light: cfg.geofence.color.lightness,
            }
        },
        mounted() {
            console.log('geofence mounted!')

            this.initData()

            this.timer = setInterval(() => {
                if (this.geofenceSaveTimer) {
                    this.geofenceSaveTimer--
                }
                if (!this.geofenceSaved && this.geofenceSaveTimer == 0) {
                    this.geofenceSaved = true
                    // emit event
                    // что делает эта штука?
                    this.$emit('change', this.getDataAsGeoJSON())
                    console.log('⚡️ save emit')
                    // что делает эта штука?
                    
                    
                    this.geofences = this.getDataAsGeoJSON()
                }
            }, 100)
        },
        created() {
            console.log('geofence created!')
        },
        destroyed() {
            return this.timer ? clearInterval(this.timer) : false
        },
        methods: {
            onClickSave() {
                console.log('onClickSave')
                // if (this.timer) clearInterval(this.timer)
                this.geofenceSaveTimer = 0
                this.needSave = false
                this.$store.commit('needSave', this.needSave)

                this.geofences.date = moment().format('YYYY-MM-DD HH:mm:ss')

                console.log('geofences0', this.geofences)
                this.$store
                    .dispatch('geofences_set', this.geofences)
                    .then(res => {
                        if (res.data.success) {
                            console.log('geofences save ok')
                        } else {
                            console.log('geofences save failure')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        // this.$store.dispatch('logout').then(() => this.$router.push('/login'))
                    })
            },
            onLogout() {
                this.$store.dispatch('logout').then(() => this.$router.push('/login'))
            },
            geofenceColorsUpdate() {
                this.colors = this.generateHslColors(cfg.geofence.color.saturation, cfg.geofence.color.lightness, cfg.geofence.color.count)
            },
            geofenceSave() {
                this.geofenceSaved = false
                this.geofenceSaveTimer = cfg.geofence.savetime
                console.log('timer start for saving')
            },
            onNameSelect() {
                this.geofenceSelected.properties.geofence.name = this.geofence.name
                this.geofenceSave()
            },
            onColorSelect(color) {
                console.log('onColorSelect', color)
                console.log('this.geofenceSelected', this.geofenceSelected)
                this.geofence.color = color

                const colorCode = this.colors[this.geofence.color]

                this.geofenceSelected.setStyle({
                    fillColor: colorCode,
                })
                this.geofenceSelected.properties.geofence.color = this.geofence.color
                this.geofenceSave()
            },
            onNotifySelect() {
                this.geofenceSelected.properties.geofence.notify = this.geofence.notify
                this.geofenceSave()
            },
            onPriceSelect() {
                this.geofenceSelected.properties.geofence.price = this.geofence.price
                this.geofenceSave()
            },
            onDescrSelect() {
                this.geofenceSelected.properties.geofence.descr = this.geofence.descr
                this.geofenceSave()
            },
            initData() {
                this.map = L.map('map', {
                    center: [43.174284, 132.121917],
                    zoom: 11,
                    zoomControl: false,
                    doubleClickZoom: false,
                    attributionControl: false,
                })
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map)

                this.geofenceColorsUpdate()
                this.colorWidth = 240 / cfg.geofence.color.count

                // set global options
                this.map.pm.setGlobalOptions({
                    allowSelfIntersection: true,
                    snappable: true,
                })

                // set language
                this.map.pm.setLang('ru')

                // add controls
                this.map.pm.addControls({
                    position: 'topleft',
                    drawPolygon: true,
                    drawMarker: false,
                    drawCircleMarker: false,
                    drawPolyline: false,
                    drawRectangle: true,
                    drawCircle: false,
                    editMode: true,
                    dragMode: true,
                    cutPolygon: false,
                    removalMode: true,
                })

                // set path style
                this.map.pm.setPathOptions(this.styleInit)

                const local_geofences = this.$store.state.geofences
                let server_geofences = {}

                this.$store.dispatch('geofences_get').then(res => {
                    if (res.data.success) {
                        server_geofences = JSON.parse(res.data.geofences.data)
                    }

                    console.log('local_geofences', local_geofences)
                    console.log('server_geofences', server_geofences)

                    console.log('server date, local date', server_geofences.date, local_geofences.date)
                    const date_server_is_last = !moment(local_geofences.date).isAfter(server_geofences.date)
                    console.log('date_server_is_last', date_server_is_last)

                    if (!local_geofences.date || (server_geofences && date_server_is_last)) {
                        this.geofences = server_geofences
                        console.log('geofences from server')
                    } else if (local_geofences.date) {
                        this.geofences = local_geofences
                        console.log('geofences from local')
                        this.needSave = true
                        this.$store.commit('needSave', this.needSave)
                    } else {
                        this.geofences = {}
                        console.log('geofences empty')
                    }

                    this.importGeoJSON(this.geofences)
                })


                this.map.on('pm:drawstart', e => {
                    console.log('⚡️ drawstart')
                    this.geofenceDraw = true
                })

                this.map.on('pm:drawend', e => {
                    console.log('⚡️ drawend')
                    this.geofenceDraw = false
                })

                this.map.on('pm:snap', e => {
                    console.log('⚡️ pm:snap')
                })

                this.map.on('pm:unsnap', e => {
                    console.log('⚡️ pm:unsnap')
                })

                this.map.on('pm:globaldrawmodetoggled', e => {
                    console.log('⚡️ pm:globaldrawmodetoggled')
                    if (this.geofenceSelected) {
                        this.geofenceSelected.setStyle(this.styleInit)
                        this.geofenceSelected.pm.disable()
                        this.geofenceSelected = null
                    }
                })

                this.map.on('pm:globaleditmodetoggled', e => {
                    console.log('⚡️ pm:globaleditmodetoggled')
                    this.modeDeselect()
                })

                this.map.on('pm:globaldragmodetoggled', e => {
                    console.log('⚡️ pm:globaldragmodetoggled')
                    this.modeDeselect()
                })

                this.map.on('pm:globalremovalmodetoggled', e => {
                    console.log('⚡️ pm:globalremovalmodetoggled')
                    this.modeDeselect()
                })

                this.map.on('pm:create', e => {
                    console.log('⚡️ pm:create e', e)
                    let color
                    if (cfg.geofence.color.random) {
                        color = this.getRUIterator(cfg.geofence.color.count).generate()
                    } else {
                        color = cfg.geofence.color.index
                    }
                    const color_hsl = this.colors[color]

                    e.layer.setStyle({
                        fillColor: color_hsl,
                    })
                    e.layer.properties = {}
                    e.layer.properties.geofence = {
                        id: uuidv4(),
                        color,
                        name: cfg.geofence.name,
                        notify: cfg.geofence.notify,
                        price: cfg.geofence.price.slice()
                    }

                    this.layerAddEvents(e.layer)
                })

                this.map.on('click', e => {
                    console.log('⚡️ click map 1')
                    if (!this.map.pm.globalEditModeEnabled() && !this.map.pm.globalDragModeEnabled()) {
                        console.log('⚡️ click map 2')
                        if (!this.geofenceOver && this.geofenceSelected && !this.markerDrag) {
                            console.log('⚡️ click map 3')
                            this.modeDeselect()
                        }
                    }
                    console.log('⚡️ click map 4')
                })

                this.map.on('mouseup', e => {
                    console.log('⚡️ mouseup map')
                })

                this.map.on('move', e => {
                    console.log('⚡️ move map')
                })

                this.map.on('keydown', e => {
                    console.log(e)
                    if (e.originalEvent.code == 'Delete') {
                        if (this.geofenceSelected) {
                            this.map.removeLayer(this.geofenceSelected)
                            this.geofenceSelected = null
                        }
                    } else if (e.originalEvent.code == 'Escape') {
                        if (this.geofenceSelected) {
                            const layer = this.geofenceSelected
                            this.modeDeselect()
                            if (this.geofenceOver) {
                                layer.setStyle(this.styleSelect)
                            }
                        }
                    }
                    this.mapUpdated(e)
                })

                // listen to events
                this.map.on('pm:create', this.mapUpdated)
                this.map.on('pm:remove', this.mapUpdated)
            },
            layerAddEvents(layer) {
                layer.on('mouseover', e => {
                    console.log('⚡️ mouseover')
                    console.log('this.styleSelect', this.styleSelect)
                    console.log('e.target', e.target)
                    e.target.setStyle(this.styleSelect)
                    e.target.bringToFront()
                    this.geofenceOver = true
                })

                layer.on('mouseout', e => {
                    console.log('⚡️ mouseout')
                    if (this.geofenceSelected != e.target) {
                        e.target.setStyle(this.styleInit)
                    }
                    this.geofenceOver = false
                })

                layer.on('pm:dragstart', e => {
                    console.log('⚡️ pm:dragstart')
                    this.geofenceDrag = true
                })

                layer.on('pm:dragend', e => {
                    console.log('⚡️ pm:dragend')
                    this.geofenceDrag = false
                })

                layer.on('pm:markerdragstart', e => {
                    console.log('⚡️ pm:markerdragstart')
                    this.markerDrag = true
                    console.log('this.markerDrag', this.markerDrag)
                })

                layer.on('pm:markerdragend', e => {
                    console.log('⚡️ pm:markerdragend')

                    setTimeout(() => {
                        this.markerDrag = false
                        console.log('this.markerDrag', this.markerDrag)
                    }, 100)
                })

                layer.on('pm:update', e => {
                    console.log('⚡️ pm:update')
                })

                layer.on('pm:drag', e => {
                    if (this.geofenceSelected) {
                        this.geofenceSelected.pm.enable()
                    }
                })

                layer.on('click', event => {
                    console.log('click', event)
                    if (!this.map.pm.globalEditModeEnabled() && !this.map.pm.globalDragModeEnabled()) {
                        if (!this.geofenceDrag && !this.geofenceDraw) {
                            if (this.geofenceSelected && this.geofenceSelected != event.target) {
                                this.modeDeselect()
                            }
                            this.geofenceSelected = event.target
                            event.target.pm.enable()
                        }
                    }
                    this.geofence = event.target.properties.geofence
                    console.log('this.geofenceSelected', this.geofenceSelected)
                    
                })
            },
            mapUpdated(event) {
                console.log('mapUpdated before', this.geofence)
                // add listeners on creation and delete on removal
                if (event.type === 'pm:create') {
                    // add data
                    event.layer.properties.shape = event.shape

                    // event.layer.properties = {
                    //     shape: event.shape,
                    // }

                    // radius for circles
                    if (event.shape === 'Circle') {
                        event.layer.properties.radius = event.layer.getRadius()
                    }
                }
                if (event.type === 'pm:remove') {
                    event.layer.off() // remove all event listeners
                }

                // emit event
                // this.$emit('change', this.getDataAsGeoJSON())

                this.geofences = this.getDataAsGeoJSON()
                console.log('mapUpdated after', this.geofence)
            },

            // export data as GeoJSON object
            getDataAsGeoJSON() {
                // create FeatureCollection
                const geoJSON = {
                    type: 'FeatureCollection',
                    features: [],
                }

                    console.log('getDataAsGeoJSON map', this.map)
                // export each layer
                this.map.eachLayer(function(layer) {
                    console.log('getDataAsGeoJSON layer', layer)
                    if (layer.properties && layer.properties.geofence && (layer instanceof L.Path || layer instanceof L.Marker)) {
                        const geoJSONShape = layer.toGeoJSON(16) // to precise geo shape!
                        geoJSONShape.properties = layer.properties
                        geoJSONShape.properties.options = layer.options
                        // geoJSONShape.properties.geofence = layer.geofence
                        // geoJSONShape.options = layer.options
                        // geoJSONShape.geofence = layer.geofence
                        geoJSON.features.push(geoJSONShape)

                        // normalize coordinates (> 180/>90)
                        // TODO
                    }
                })
                geoJSON.date = moment().format('YYYY-MM-DD HH:mm:ss')

                this.$store.commit('geofences', geoJSON)
                this.needSave = true
                this.$store.commit('needSave', this.needSave)

                return geoJSON
            },

            // import data from GeoJSON
            importGeoJSON(geoJSON) {
                // console.log('geoJSON', JSON.stringify(geoJSON))
                if (geoJSON && geoJSON.type === 'FeatureCollection' && geoJSON.features && geoJSON.features.length) {
                    geoJSON.features.forEach(feature => {
                        const shape = feature.properties && feature.properties.shape
                        const coordinates = feature.geometry && feature.geometry.coordinates
                        console.log('feature.properties', feature.properties)
                        // const options = Object.assign({}, feature.properties.options)
                        let layer // define shape for later use
                        switch (shape) {
                            case 'Circle':
                                layer = new L.Circle([coordinates[1], coordinates[0]], feature.properties.radius)
                                break
                            case 'CircleMarker':
                                layer = new L.CircleMarker([coordinates[1], coordinates[0]])
                                break
                            case 'Marker':
                                layer = new L.Marker([coordinates[1], coordinates[0]])
                                break
                            case 'Polyline':
                                layer = new L.Polyline(this.switchCoordinates(coordinates))
                                break
                            case 'Polygon':
                                layer = new L.Polygon(this.switchCoordinates(coordinates), feature.properties.options)
                                break
                            case 'Rectangle':
                                layer = new L.Rectangle(this.switchCoordinates(coordinates), feature.properties.options)
                                break
                        }


                        if (layer) {
                            // this.map.pm.setPathOptions(this.styleInit)
                            layer.setStyle({
                                weight: 1,
                            })
                            layer.addTo(this.map)
                            layer.properties = feature.properties
                            // layer.options = feature.properties.options
                            // layer.geofence = feature.properties.geofence

                            console.log('layer', layer)

                            // add event listener
                            layer.on('pm:edit', this.mapUpdated)

                            this.layerAddEvents(layer)
                        }
                    })
                }
            },
            // switch coordinates -> geoJSON to Leaflet
            switchCoordinates(coordinates) {
                return [coordinates[0].map(pair => [pair[1], pair[0]])]
            },
            generateHslColors(saturation, lightness, amount) {
                let colors = []
                let huedelta = Math.trunc(360 / amount)
                for (let i = 0; i < amount; i++) {
                    let hue = i * huedelta
                    colors.push(`hsl(${hue},${saturation}%,${lightness}%)`)
                }
                return colors
            },
            getRUIterator(max) {
                // Итератор случайных уникальных - Random Unique
                let buffer = []
                const ruIterator = {
                    generate: function() {
                        if (buffer.length === 0) {
                            // если колода пуста
                            for (let i = 0; i < max; i++) buffer.push(i) // открываем новую 0..max
                        }
                        const index = Math.floor(Math.random() * buffer.length)
                        return buffer.splice(index, 1)[0] // вынимаем из колоды случайную карту
                    },
                }
                return ruIterator
            },
            modeDeselect() {
                console.log('⚡️ modeDeselect')
                console.log('this.geofenceSelected', this.geofenceSelected)
                if (this.geofenceSelected) {
                    this.geofenceSelected.setStyle(this.styleInit)
                    this.geofenceSelected.pm.disable()
                    this.geofenceSelected = null
                }
            },
        },
    }
</script>

<style lang="stylus">
    .geofences
        height 100%
        #map
            width 100%
            height 100%
            z-index 1

        .map-wrapper
            width 100%
            height 100%
            position absolute
            display flex

        .banner
            background-color white !important
            position fixed !important
            top 90px
            right 20px
            width 350px
            z-index 1

        .colors_wrapper
            display inline-flex
            line-height 30px

            .header
                width 50px

            .color
                height 30px

                &.selected
                    border 2px solid white !important

                &:hover
                    border 2px solid white
                    cursor pointer

        .v-alert
            width 400px
            position absolute
            display flex
            bottom 2px
            left 90px
            z-index 2

</style>
